<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <response-form-card
        v-if="selectedResponse !== null"
        :reloadParent="load"
        :response="selectedResponse"
        :closeSidebar="closeSidebar"
        :questionnaireVendorId="questionnaireVendorId"
        :fullquestions="responses"
        :qindex="qindex"
      />
    </b-sidebar>
    <div class="card" v-if="assessment">
      <div
        class="card-header d-flex flex-row align-items-start justify-content-between"
      >
        <div
          class="d-flex flex-column align-items-start justify-content-start"
          style="width: 50%"
        >
          <h2 class="mb-1 text-primary">{{ assessment.title }}</h2>
          <p>{{ assessment.description }}</p>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-between"
          style="width: 30%"
        >
          <h4>Progress</h4>
          <div class="d-flex justify-content-center align-items-center">
            <donut-bar
              :percentage="Number(assessmentProgress.toFixed(2))"
              :displayValue="assessmentProgress + '%'"
              size="sm"
              :uniqueId="assessment._id + 2"
              :colorsReversed="true"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div
        class="card-header d-flex flex-column align-items-start justify-content-start"
      >
        <h4 class="text-turncate mb-50">Questions & Responses</h4>
        <p v-if="isUserVendor == true" class="mb-0text-muted font-weight-bold">
          Click on any question to start the assessment.
        </p>
        <p v-else class="mb-0text-muted font-weight-bold">
          Click on any question to view the assessment.
        </p>
        <!-- {{this.showStartbtn}} -->
      </div>
      <div class="float-right">
        <button
          class="btn btn-success mb-1 float-right mr-1"
          @click="startAssess()"
          v-if="markstatus == 1"
        >
          <span v-if="is_new">Start Assesment</span>
          <span v-else> Continue assessment</span>
        </button>

        <!-- {{this.restAssessmentqs[0]}} -->
      </div>
      <div class="card-body">
        <!-- <table role="table" class="table table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">#</th>
              <th role="columnheader" scope="col">Question</th>
              <th role="columnheader" scope="col">Description</th>
              <th role="columnheader" scope="col" class="text-center">
                Response
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="(question, i) in responses"
              :key="question._id"
              role="row"
              class="cursor-pointer"
              @click="handleQuestionClick(question)"
            >
              <td role="cell" style="width: 20px; padding-right: 0">
                <div class="d-flex justify-content-start">
                  <p class="text-left font-weight-bold mb-0">
                    {{ i + 1 }}
                  </p>
                </div>
              </td>
              <td role="cell" style="width: 360px; padding-right: 0">
                <div class="d-flex justify-content-start" style="width: 360px">
                  <p class="text-left text-primary font-weight-bold mb-0">
                    {{ question.title }}
                  </p>
                </div>
              </td>

              <td role="cell" style="width: 360px; padding-right: 0">
                <div class="d-flex justify-content-start" style="width: 360px">
                  <p class="text-left font-italic font-weight-bold mb-0">
                    {{ question.description }}
                  </p>
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-badge variant="primary">{{
                    getResponseValue(question.response)
                  }}</b-badge>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->

        <div
          class="d-flex cursor-pointer justify-content-between align-items-center border mb-1 p-1 rounded"
          style="background: #767187; color: #fff"
        >
          <div class="font-weight-bold" style="width: 70%">Question</div>
          <!-- <div>{{question.description}}</div> -->
          <div>
            <p>Response</p>
          </div>
        </div>
        <!-- {{ markstatus }} -->
        <div
          :class="
            markstatus == 1
              ? 'd-flex cursor-pointer justify-content-between p-1 border mb-1 rounded'
              : 'd-flex cursor-pointer justify-content-between p-1 border mb-1 rounded not-active'
          "
          v-for="(question, i) in responses"
          :key="question._id"
          @click="handleQuestionClick(question, i)"
          title="Click to start assessment"
          style="border-left: 3px solid #7367f0 !important"
        >
          <div class="font-weight-bold d-flex" style="width: 70%">
            <span class="text-primary mr-1">Q{{ i + 1 }}</span>
            <span>{{ question.title }}</span>
          </div>
          <div
            style="
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: end;
            "
          >
            <div>
              <b-badge
                variant="warning"
                v-if="!question.downloadable_evidences.length"
                class="mr-1"
              >
                <span v-if="question.is_evidence_rqd == 1"
                  >Evidence Required</span
                >
              </b-badge>
            </div>

            <div>
              <b-badge variant="primary">{{
                getResponseValue(question.response)
              }}</b-badge>
            </div>
            <div
              v-if="question.evidences.length && question.response"
              class="ml-1"
            >
              <feather-icon icon="CheckCircleIcon" class="text-success" />
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <button
            class="btn btn-primary ml-1"
            @click="markComplete"
            v-if="markstatus == 1"
          >
            <span> mark us complete</span>
          </button>
          <button class="btn btn-success ml-1" v-if="markstatus == 2">
            <span> Completed</span>
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="card" v-if="assessment !== null">
      <div class="card-header">
        <div class="d-flex flex-column align-items-start justify-content-start">
          <h2 class="mb-1">Questionnaires</h2>
        </div>
      </div>
      <div class="card-body">
        <table role="table" class="table table-hover">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">Title</th>
              <th role="columnheader" scope="col">Description</th>
              <th role="columnheader" scope="col" class="text-center">
                Total Questions
              </th>
              <th role="columnheader" scope="col" class="text-center">Gap</th>
              <th role="columnheader" scope="col" class="text-center">
                Progress
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <tr
              v-for="questionnaire in assessment.questionnaires"
              :key="questionnaire._id"
              role="row"
              class="cursor-pointer"
              @click="handleQuestionnaireClick(questionnaire._id)"
            >
              <td role="cell" style="width: 300px">
                <div class="d-flex justify-content-start" style="width: 300px">
                  <p class="text-left text-primary font-weight-bold mb-0">
                    {{ questionnaire.title }}
                  </p>
                </div>
              </td>

              <td role="cell" style="width: 300px">
                <div class="d-flex justify-content-start" style="width: 300px">
                  <p class="text-left font-italic font-weight-bold mb-0">
                    {{ questionnaire.description }}
                  </p>
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :text="questionnaire.total_questions.toString()"
                    variant="light-primary"
                  />
                </div>
              </td>

              <td role="cell">
                <div class="d-flex justify-content-center">
                  <donut-bar
                    :percentage="Number(questionnaire.gap.toFixed(2))"
                    :displayValue="questionnaire.gap + '%'"
                    size="xsm"
                    :uniqueId="questionnaire._id + 1"
                  />
                </div>
              </td>
              <td role="cell">
                <div class="d-flex justify-content-center">
                  <donut-bar
                    :percentage="Number(questionnaire.completion.toFixed(2))"
                    :displayValue="questionnaire.completion + '%'"
                    size="xsm"
                    :uniqueId="questionnaire._id + 2"
                    :colorsReversed="true"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
  </b-overlay>
</template>

<script>
import { BOverlay, BSidebar, BButton, BAvatar, BBadge } from "bootstrap-vue";
import DonutBar from "@/components/DonutBar.vue";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import VendorMixins from "../../../mixins/VendorMixins";
import ResponseFormCard from "./components/ResponseFormCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    DonutBar,
    ResponseFormCard,
    BBadge,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      selectedResponse: [],
      activeResponseId: null,
      currentResponseIndex: 0,
      importedQuestionnaire: null,
      questionnaireVendorId: null,
      assessment: null,
      responses: [],
      filters: {
        page: 1,
        perPage: 10,
        lastPage: 1,
      },
      isUserVendor: false,
      assessmentProgress: 0,
      allquestions: [],
      qindex: "",
      currentAssessIndex: null,
      restAssessmentqs: [],
      showStartbtn: true,
      markstatus: "",
      is_new: true,
    };
  },

  mixins: [
    ThirdPartyRisksMixins,
    ThirdPartyRisksRAMixins,
    QuestionnaireMixins,
    ResponseMixins,
    UtilsMixins,
    TaskMixins,
    VendorMixins,
  ],

  mounted() {
    this.load();
    this.startBtn();
  },
  methods: {
    markComplete() {
      const data = {
        status: 2,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/vendors/${this.$store.state.app.user.vendor_id}/assessments/${this.$route.params.assessmentId}/status`,
      };
      this.$http(options)
        .then((res) => {
          this.load();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    currentAssesmentqs() {
      var i = 0;
      for (i = 0; i < this.responses.length; i++) {
        if (this.responses[i].status == null) {
          this.currentAssessIndex = i;
          if (i > 0) {
            break;
          }
        } else {
          this.is_new = false;
        }
      }
     
    },
    startAssess(){
       if (this.currentAssessIndex != null) {
        this.handleQuestionClick(
          this.responses[this.currentAssessIndex],
          this.currentAssessIndex
        );
      }
    },

    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }
      this.getAssessmentDetailsAndSetData(
        this.$store.state.app.user.vendor_id,
        this.$route.params.assessmentId
      );
      
    },

    getAssessmentDetailsAndSetData(vendorId, assessmentId) {
      this.getAssessmentDetailsOfVendor(vendorId, assessmentId)
        .then((res) => {
          this.responses = [];
          this.assessment = res.data.data;
          this.allquestions = res.data.data.questionnaires;
          this.markstatus = res.data.data.status;
          let questionnaires = res.data.data.questionnaires;
          let allProgress = 0;
          questionnaires.map((questionnaire) => {
            allProgress = allProgress + questionnaire.completion;
            questionnaire.responses.map((response) => {
              // console.log("Response", response);

              response["vendor_id"] = questionnaire.vendor_id;
              //Dont Show Not Applicable
              if (
                (response.parent_question_id == null &&
                  response.is_response_mandatory == 1) ||
                (response.parent_question_id != null &&
                  response.parent_response.response ==
                    response.parent_question_response)
              ) {
                response["response_options"] = [
                  { value: null, text: "--Select a value--" },
                  { value: 1, text: "Yes" },
                  { value: 2, text: "No" },
                ];
              } else {
                response["response_options"] = [
                  { value: null, text: "--Select a value--" },
                  { value: 1, text: "Yes" },
                  { value: 2, text: "No" },
                  { value: 3, text: "Not Applicable" },
                ];
              }
              this.responses.push(response);
            });
          });
          this.assessmentProgress = allProgress / questionnaires.length;

          this.currentAssesmentqs();//checking assesment is start or continue
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleQuestionClick(question, index) {
      this.qindex = index;
      this.questionnaireVendorId = question.vendor_id;
      this.setSelectedResponseData(question);
      this.openSidebar();
    },
    setSelectedResponseData(response) {
      this.selectedResponse = response;
      this.activeResponseId = response._id;
      this.currentResponseIndex = this.responses.findIndex(
        (res) => res._id == response._id
      );
    },
    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Unknown";
        }
      }
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
.not-active {
  pointer-events: none;
  cursor: default;
}
</style>
