<template>
  <div class="card w-100 border-light">
    <!-- {{fullquestions[qindex]}} -->
    <!-- {{ formData }} -->
    <div
      class="card-header d-flex flex-row align-items-start justify-content-between"
    >
      <div
        style="width: 70%"
        class="d-flex justify-content-start align-items-center"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          style="height: 100%"
        >
          <feather-icon
            icon="HelpCircleIcon"
            class="mr-1 text-primary"
            size="18"
          />
        </div>
        <div class="d-flex flex-column align-itesm-start justify-content-start">
          <h4 class="mb-0">
            <span class="align-middle text-primary">{{
              fquestions[qsindex].title
            }}</span>
          </h4>
          <p class="font-weight-bold text-muted mb-0">
            {{ fquestions[qsindex].description }}
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <b-button @click="closeSidebar()" variant="danger"
          ><feather-icon icon="XIcon"
        /></b-button>
      </div>
    </div>
    <div class="card-body p-0">
      <validation-observer ref="assess_form" #default="{ invalid }">
        <b-form
          class="mt-2 d-flex flex-column align-items-center justify-content-start w-100 px-2"
          @submit.prevent
        >
          <b-form-group
            label="Response"
            class="w-100"
            v-if="
              fquestions[qsindex].response_type &&
              fquestions[qsindex].response_type == 1
            "
          >
            <template slot="label">
              Response <span class="text-danger">*</span>
            </template>
            <validation-provider
              vid="response_text"
              #default="{ errors }"
              name="Response"
              :rules="{
                required: true,
              }"
            >
              <b-form-textarea
                rows="3"
                max-rows="6"
                v-model="formData.response_text"
                :disabled="$store.state.app.user.vendor_id !== vendorId"
                class="w-100"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Response" class="w-100" v-else>
            <template slot="label">
              Response <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Response"
              :rules="{
                required: true,
              }"
            >
              <b-form-select
                v-model="formData.response"
                :options="response.response_options"
                :disabled="$store.state.app.user.vendor_id !== vendorId"
                class="w-100"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- <b-form-group label="Recommendation" class="w-100">
            <validation-provider
              vid="recommendation"
              #default="{ errors }"
              name="Recommendation"
            >
              <b-form-textarea
                v-model="formData.recommendation"
                :disabled="$store.state.app.user.vendor_id !== vendorId"
                rows="6"
                max-rows="6"
                id="recommendation"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Observation" class="w-100">
            <validation-provider
              vid="observation"
              #default="{ errors }"
              name="observation"
            >
              <b-form-textarea
                id="observation"
                v-model="formData.observation"
                :disabled="$store.state.app.user.vendor_id !== vendorId"
                rows="6"
                max-rows="6"
                :state="errors.length > 0 ? false : null"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <b-form-group label="Note" class="w-100">
            <validation-provider vid="note" #default="{ errors }" name="Note">
              <b-form-textarea
                id="note"
                v-model="formData.note"
                :disabled="$store.state.app.user.vendor_id !== vendorId"
                rows="3"
                max-rows="3"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Evidence" class="w-100">
            <validation-provider
              vid="evidence_files"
              #default="{ errors }"
              name="Evidence"
            >
              <b-form-file
                v-model="formData.evidenceFiles"
                placeholder="Choose Evidence Files"
                drop-placeholder="Drop file here..."
                multiple
                :state="errors.length > 0 ? false : null"
                :disabled="$store.state.app.user.vendor_id !== vendorId"
              >
                <template slot="file-name" slot-scope="{ names }">
                  <b-badge
                    v-for="name in names"
                    :key="name"
                    variant="light-primary"
                    class="mr-75"
                    >{{ name }}</b-badge
                  >
                </template>
              </b-form-file>
              <div
                class="w-100 d-flex flex-column align-items-start justify-content-center"
              >
                <small
                  :key="error"
                  v-for="error in errors"
                  class="text-danger mb-25"
                >
                  {{ error }}
                </small>
              </div>
            </validation-provider>
          </b-form-group>

          <div
            class="w-100 d-flex justify-content-between align-items-center my-2"
          >
            <button
              class="btn btn-outline-primary mr-1"
              style="margin-top: 0rem !important"
              @click="previous()"
              :disabled="qsindex == 0"
            >
              Previous
            </button>
            <b-button
              type="submit"
              variant="success"
              class="btn-block mr-1"
              :disabled="
                $store.state.app.user.vendor_id !== vendorId || invalid
              "
              style="margin-top: 0rem !important"
              @click="handleSubmitClick"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Submit</span>
            </b-button>
            <button
              class="btn btn-outline-primary"
              style="margin-top: 0rem !important"
              @click="next()"
              :disabled="fquestions.length == qsindex + 1"
            >
              Next
            </button>
          </div>

          <div class="card mb-0 w-100">
            <div
              class="card-header d-flex align-items-start justify-content-start"
            >
              <h5 class="mb-0">Uploaded Evidences</h5>
            </div>
            <div
              class="card-body mb-0 d-flex flex-column justify-content-start align-items-center"
              style="max-height: 700px; position: relative"
            >
              <template v-if="!response['downloadable_evidences']">
                <div
                  style="width: 100%; height: 100%"
                  class="bg-light-primary rounded py-2 d-flex flex-column justify-content-center align-items-center"
                >
                  <p class="font-weight-bold">No Files Uploaded</p>
                  <feather-icon size="24" icon="UploadIcon" />
                </div>
              </template>

              <template v-else>
                <div
                  style="width: 100%; height: 410px"
                  class="table-wrapper-scroll-y my-custom-scrollbar"
                >
                  <table role="table" class="table table-light">
                    <thead role="rowgroup">
                      <tr role="row">
                        <th
                          role="columnheader"
                          scope="col"
                          class="header bg-light"
                        >
                          File
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          class="header bg-light"
                        >
                          Uploaded By
                        </th>
                        <th
                          role="columnheader"
                          scope="col"
                          class="header bg-light"
                        >
                          Uploaded At
                        </th>
                      </tr>
                    </thead>
                    <tbody role="rowgroup">
                      <tr
                        v-for="evidence in formData.downloadable_evidences"
                        :key="evidence.file_id"
                        role="row"
                      >
                        <td
                          aria-colindex="1"
                          role="cell"
                          style="padding-right: 0"
                        >
                          <div
                            style="width: 200px"
                            class="d-flex justify-content-start"
                          >
                            <b-badge
                              :key="evidence.file_id"
                              variant="light-primary"
                              style="position: relative"
                              class="d-flex flex-direction-column align-items-center justify-content-between my-75 cursor-pointer"
                              v-b-tooltip.hover
                              :title="
                                'Download: ' + evidence.original_file_name
                              "
                              @click="
                                handleDownloadButtonClick(
                                  evidence.original_file_name,
                                  evidence.file_id
                                )
                              "
                            >
                              <div
                                style="width: 27px"
                                class="h-100 d-flex align-items-center justify-content-center"
                              >
                                <feather-icon
                                  v-if="
                                    !downloadingList.includes(evidence.file_id)
                                  "
                                  icon="DownloadIcon"
                                  class="mr-75"
                                  size="27"
                                />
                                <b-spinner
                                  v-else
                                  class="ml-0 mr-75"
                                  variant="primary"
                                  small
                                />
                              </div>

                              <p class="truncate text-left mb-0">
                                {{ evidence.original_file_name }}
                              </p>
                            </b-badge>
                          </div>
                        </td>
                        <td aria-colindex="2" role="cell">
                          <p
                            class="mb-0 font-weight-bold"
                            v-if="
                              evidence.uploaded_user &&
                              evidence.uploaded_user !== null
                            "
                          >
                            {{ evidence.uploaded_user.firstname }}
                            {{ evidence.uploaded_user.lastname }}
                          </p>
                          <b-badge v-else variant="danger"
                            >Deleted User</b-badge
                          >
                        </td>
                        <td aria-colindex="3" role="cell">
                          {{ evidence.uploaded_at | moment }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <b-modal ref="ok-modal" hide-footer title=" Assessment" size="lg">
      <!-- {{this.formData.evidenceFiles}} -->
      <!-- <p>Response is not Checked Are You Sure to Continue?</p> -->
      <p v-if="!this.formData.evidenceFiles">
        No Evidence files selected Are you Sure to Continue?
      </p>
      <b-button variant="success" class="mr-1" @click="okSubmit()">
        <span v-if="!this.formData.evidenceFiles"> Yes & Continue</span>
        <span v-else>Submit</span>
      </b-button>
      <b-button
        v-if="!this.formData.evidenceFiles"
        variant="primary"
        class="mr-1"
        @click="okCancel()"
      >
        Add Files
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BBadge,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";
import ResponseMixins from "../../../../mixins/ResponseMixins";
import UtilsMixins from "../../../../mixins/UtilsMixins";
import TaskMixins from "../../../../mixins/TaskMixins";
import ThirdPartyRisksRAMixins from "../../../../mixins/ThirdPartyRisksRAMixins";
import moment from "moment";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    required,
    min,
    BBadge,
    BSpinner,
    VBTooltip,
  },
  data() {
    return {
      showOverlay: false,
      vendorId: null,
      responseOptions: [
        { value: null, text: "--Select a value--" },
        { value: 1, text: "Yes" },
        { value: 2, text: "No" },
        { value: 3, text: "Not Applicable" },
      ],
      formData: {
        response: null,
        response_text: null,
        note: null,
        recommendation: null,
        evidenceFiles: null,
      },
      downloadingList: [],
      fquestions: [],
      qsindex: null,
    };
  },
  props: {
    reloadParent: {
      type: Function,
      required: true,
    },
    response: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    questionnaireVendorId: {
      type: String,
      required: true,
    },
    fullquestions: Array,
    qindex: Number,
  },
  directives: { "b-tooltip": VBTooltip },
  mixins: [ThirdPartyRisksRAMixins, ResponseMixins, UtilsMixins, TaskMixins],
  watch: {
    qsindex() {
      this.formData = this.fquestions[this.qsindex];
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  mounted() {
    this.setFormDataValuesFromResponse(this.response);
    this.vendorId = this.questionnaireVendorId;
    this.fquestions = this.fullquestions;
    this.qsindex = this.qindex;
    this.formData = this.fquestions[this.qsindex];
  },
  methods: {
    refresh() {
      this.formData = this.fquestions[this.qsindex];
    },
    previous() {
      this.qsindex = this.qsindex - 1;
    },
    next() {
      this.qsindex = this.qsindex + 1;
    },
    setFormDataValuesFromResponse(response) {
      // alert(JSON.stringify(response));
      if (response.response_type && response.response_type == 1) {
        this.formData.response = 3;
      } else {
        this.formData.response = response.response;
      }
      this.formData.observation = response.observation;
      this.formData.recommendation = response.recommendation;
      this.formData.note = response.note;
      this.formData.response_text = response.response_text;
    },
    handleSubmitClick() {
      console.log("Submit", this.response);
      this.setFormDataValuesFromResponse(this.formData);
      this.showOverlay = true;
      if (this.formData.evidenceFiles) {
        this.okSubmit();
      } else {
        this.$refs["ok-modal"].show();
      }
    },
    okCancel() {
      this.$refs["ok-modal"].hide();
    },
    okSubmit() {
      this.updateVendorQuestionnaireResponse(
        this.formData,
        this.questionnaireVendorId,
        this.$route.params.assessmentId,
        this.formData.questionnaire_id,
        this.formData._id
      )
        .then((res) => {
          this.reloadParent();
          this.handleResponse(res);
          this.formData.downloadable_evidences =
            res.data.data.downloadable_evidences;

          // this.fquestions = this.fullquestions;
          // this.qsindex = this.qindex;
          // this.formData = this.fquestions[this.qsindex];
          // this.closeSidebar();
          this.formData = this.fquestions[this.qsindex];
          this.$refs["ok-modal"].hide();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleDownloadButtonClick(fileName, id) {
      if (!this.downloadingList.includes(id)) {
        this.downloadingList.push(id);
        const options = {
          method: "GET",
          responseType: "blob",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/file-uploads/${id}/download`,
        };
        this.debounceFunction(() => {
          this.$http(options)
            .then((res) => {
              var blob = new Blob([res.data]);
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, fileName);
              } else {
                var a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(blob, {
                  type: "text/plain",
                });
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }

              this.downloadingList = this.downloadingList.filter(
                (item_id) => item_id !== id
              );
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        });
      }
    },
  },
};
</script>

<style></style>
<style lang="scss" scoped>
.my-custom-scrollbar {
  position: relative;
  height: 60vh;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
